import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { PageTitle } from '../../_metronic/layout/core'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../setup'
// import CreditsPage from '../modules/credits/CreditsPage'
import ExtrernalUrls from '../modules/external/ExtrernalUrls'
// import PlaylistView from '../components/playlist/PlaylistView'
// import ConfigurationView from '../components/configuration/ConfigurationView'

const PrivateRoutes = () => {
  const role = useSelector<RootState>(({ auth }) => auth.role, shallowEqual)

  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const DevicesPage = lazy(() => import('../modules/device/DevicesPage'))
  const StatisticsPage = lazy(() => import('../modules/statistics/StatisticsPage'))
  const MessagePage = lazy(() => import('../modules/message/MessagePage'))
  const UserPage = lazy(() => import('../modules/user/UserPage'))
  const ConfigurationPage = lazy(() => import('../modules/configuration/ConfigurationPage'))
  const PlaylistPage = lazy(() => import('../modules/playlist/PlaylistPage'))
  const GeozonePage = lazy(() => import('../modules/geozone/GeozonePage'))
  const StorePage = lazy(() => import('../modules/store/StorePage'))
  const CustomerPage = lazy(() => import('../modules/customer/CustomerPage'))
  const AppButtonPage = lazy(() => import('../modules/appButtons/AppButtonPage'))
  const SchedulePage = lazy(() => import('../modules/schedule/SchedulePage'))
  const CommandsPage = lazy(() => import('../modules/commands/CommandsPage'))
  const MultiListPage = lazy(() => import('../modules/multilist/MultiListPage'))
  const CampaignPage = lazy(() => import('../modules/campaign/CampaignPage'))
  const CreditsPage = lazy(() => import('../modules/credits/CreditsPage'))
  const SubscriptionsPage = lazy(() => import('../modules/subscriptions/SubscriptionsPage'))

  const maintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE;
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {(maintenanceMode != 'true' || (maintenanceMode == 'true' && localStorage.getItem('maintenanceMode') == 'true')) ? (<>

          <Route path='/dashboard' element={<DashboardWrapper />} />

          <Route path='auth/*' element={<Navigate to='/devices/list' />} />
          {/* <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' /> */}
          <Route path='/devices/*' element={
            <SuspensedView>
              <DevicesPage />
            </SuspensedView>
          } />

          <Route path='/configuration/*' element={
            <SuspensedView>
              <ConfigurationPage />
            </SuspensedView>
          } />

          <Route path='/learn' element={
            <SuspensedView>
              <ExtrernalUrls url={'https://emely6538.softr.app'} />
            </SuspensedView>
          } />

          <Route path='/faq' element={
            <SuspensedView>
              <ExtrernalUrls url={'https://emely6538.softr.app/faq'} />
            </SuspensedView>
          } />

          <Route path='/glossary' element={
            <SuspensedView>
              <ExtrernalUrls url={'https://emely6538.softr.app/glossary'} />
            </SuspensedView>
          } />

          <Route path='/playlists/*' element={
            <SuspensedView>
              <PlaylistPage />
            </SuspensedView>
          } />

          <Route path='/campaigns/*' element={
            <SuspensedView>
              <CampaignPage />
            </SuspensedView>
          } />

          <Route path='/geo-zone/*' element={
            <SuspensedView>
              <GeozonePage />
            </SuspensedView>
          } />

          <Route path='/statistics/*' element={
            <SuspensedView>
              <StatisticsPage />
            </SuspensedView>
          } />

          <Route path='/messages/*' element={
            <SuspensedView>
              <MessagePage />
            </SuspensedView>
          } />

          <Route path='/user/*' element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          } />

          <Route path='/store/*' element={
            <SuspensedView>
              <StorePage />
            </SuspensedView>
          } />

          {role == "PG" &&
            <Route path='/customers/*' element={
              <SuspensedView>
                <CustomerPage />
              </SuspensedView>
            } />
          }

          <Route path='/credits/*' element={
            <SuspensedView>
              <CreditsPage />
            </SuspensedView>
          } />

          <Route path='/subscriptions/*' element={
            <SuspensedView>
              <SubscriptionsPage />
            </SuspensedView>
          } />

          <Route path='/schedules/*' element={
            <SuspensedView>
              <SchedulePage />
            </SuspensedView>
          } />

          <Route path='/multilist/*' element={
            <SuspensedView>
              <MultiListPage />
            </SuspensedView>
          } />

          <Route path='/app/*' element={
            <SuspensedView>
              <AppButtonPage />
            </SuspensedView>
          } />

          <Route path='/admin/*' element={
            <SuspensedView>
              <CommandsPage />
            </SuspensedView>
          } />

          <Route path='/scheduling' element={
            <SuspensedView>
              <PageTitle breadcrumbs={[
                {
                  title: 'scheduling',
                  path: 'configuration',
                  isSeparator: false,
                  isActive: false,
                }
              ]}>Scheduling</PageTitle>
              <></>
            </SuspensedView>
          } />

          {/* <Redirect to='error/404' /> */}

          <Route
            path='crafted/pages/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route path='*' element={<Navigate to='/error/404' />} />
        </>) :
          (<>
            <Route path='*' element={<Navigate to='/maintenance' />} />
          </>)}
      </Route>

      {/* <Route path='/mobile/playlist' element={
        <PlaylistView />
      } />

      <Route path='/mobile/configuration' element={
        <ConfigurationView />
      } /> */}
    </Routes>
  )
}

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }